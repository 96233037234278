<template lang="pug">
  v-card
    .create-new-modal
      v-card-title
        h2.card-title {{ 'modals.expense.create.title' | translate }}
      v-card-content
        v-select.mb-2(
          :label="$t('base.ingredient')"
          :options="ingredientsList"
          v-model="form.ingredient")
        v-select.mb-3(
          :label="$t('base.provider')"
          :options="buyersList"
          v-model="form.supplier")
        .create-new-modal__tabs-wrap
          ul.create-new-modal__tabs-list
            li.create-new-modal__tabs-item(
              @click="activeTab = 'Вага'"
              :class="activeTab === 'Вага' ? 'create-new-modal__tabs-item--active' : null"
            ) {{ 'base.weight' | translate }}
            li.create-new-modal__tabs-item(
              @click="activeTab = 'Мішки'"
              :class="activeTab === 'Мішки' ? 'create-new-modal__tabs-item--active' : null"
            ) {{ 'base.bags' | translate }}
            li.create-new-modal__tabs-item(
              @click="activeTab = 'Мішки з QR'"
              :class="activeTab === 'Мішки з QR' ? 'create-new-modal__tabs-item--active' : null"
            ) {{ 'base.bags_with_qr' | translate }}
          .create-new-modal__tabs-body.mt-2
            template(v-if="activeTab === 'Вага'")
              .cols-x
                .col-x-2.mb-2
                  v-text-field(
                    color-gray
                    :label="$t('base.price')"
                    v-model="form.price")
                .col-x-2.mb-2
                  v-text-field(
                    color-gray
                    :label="$t('base.price_batch')"
                    v-model="form.price_batch")
              .cols-x
                .col-x-2.mb-2
                  v-text-field(
                    color-gray
                    :label="$t('base.weight')"
                    v-model="form.weight")
                .col-x-2.mb-2
                  v-text-field(
                    color-gray
                    :label="$t('base.weight_total')"
                    v-model="form.weight_total")
              .cols-x
                .col-x-2(
                  :class="{ 'mb-2': isMobile }")
                  v-text-field(
                    :label="$t('base.weight_control')"
                    v-model="form.weight_control")
                .col-x-2
                  v-text-field(
                    :label="$t('base.weight_net')"
                    v-model="form.weight_net")
            template(v-if="activeTab === 'Мішки'")
              .cols-x
                .col-x-2.mb-2
                  v-text-field(
                    :label="$t('base.price')"
                    v-model="form.price")
                .col-x-2.mb-2
                  v-text-field(
                    color-gray
                    :label="$t('base.price_batch')"
                    v-model="form.price_batch")
              .cols-x
                .col-x-2.mb-2
                  v-text-field(
                    :label="$t('base.weight_bag')"
                    v-model="form.weight_bag")
                .col-x-2.mb-2
                  v-text-field(
                    :label="$t('base.weight_batch')"
                    v-model="form.weight_batch")
              .cols-x
                .col-x-2
                  v-text-field(
                    :label="$t('base.weight_quantity')"
                    v-model="form.weight_quantity")
            template(v-if="activeTab === 'Мішки з QR'")
              .cols-x
                .col-x-2.mb-2
                  v-text-field(
                    :label="$t('base.price')"
                    v-model="form.price")
                .col-x-2.mb-2
                  v-text-field(
                    :label="$t('base.price_batch')"
                    v-model="form.price_batch")
              .cols-x
                .col-x-2.mb-2
                  v-text-field(
                    :label="$t('base.weight_bag')"
                    v-model="form.weight_bag")
                .col-x-2.mb-2
                  v-text-field(
                    :label="$t('base.weight_batch')"
                    v-model="form.weight_batch")
              .cols-x
                .col-x-2(
                  :class="{ 'mb-2': isMobile }")
                  v-text-field(
                    :label="$t('base.weight_quantity')"
                    v-model="form.weight_quantity")
                .col-x-2
                  v-upload(
                    v-model="form.qrcode")
      v-card-actions
        v-btn(
          :disabled="!isIngredientSelected || !isPriceValid || !isWeightValid || !isSupplierSelected"
          :loading="loading"
          @click="onCreate") {{ 'actions.create' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreateNewModal',

  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    activeTab: 'Вага',
    form: {
      ingredient: null,
      price: '',
      price_batch: '',
      weight: '',
      weight_batch: '',
      weight_total: '',
      weight_control: '',
      weight_net: '',
      weight_quantity: '',
      supplier: null,
      qrcode: ''
    },
    loading: false
  }),

  computed: {
    isIngredientSelected () {
      return this.form.ingredient !== null
    },

    isSupplierSelected () {
      return this.form.supplier !== null
    },

    isPriceValid () {
      return !!this.form.price
    },

    isWeightValid () {
      return !!this.form.weight
    },

    ...mapGetters([
      'ingredientsList',
      'buyersList'
    ])
  },

  mounted () {
    this.fetchIngredientsList()
    this.fetchBuyersList()
  },

  methods: {
    ...mapActions([
      'fetchIngredientsList',
      'fetchBuyersList'
    ]),

    async onCreate () {
      this.loading = true
      const payload = {
        ingredient_id: this.form.ingredient,
        weight: +this.form.weight,
        price: +this.form.price,
        provider_id: this.form.supplier
      }
      if (this.form.bags) {
        payload.bugs = +this.form.bags
      }
      const { success, message } = await this.$store.dispatch('addWriteOff', payload)
      this.loading = false
      if (success) {
        this.$emit('close')
      } else {
        this.$toasted.show(this.$t(`errors.${message}`), {
          position: 'bottom-center',
          duration: 5000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .create-new-modal {
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__select {
      width: calc(100% - 5.5rem);
    }

    &__qr {
      display: inline-flex;
      outline: none;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }

    &__tabs-wrap {
      // padding: 1rem 2rem 2rem;
      background-color: $color-gray-light-3;
      border: .1rem solid $color-gray-light-2;
      border-radius: .8rem;
    }

    &__tabs-body {
      padding: 0 2rem 2rem;
    }

    &__tabs-list {
      padding: 1rem 2rem 0;
      display: flex;
      border-bottom: .1rem solid $color-gray-light-2;
    }

    &__tabs-item {
      padding-bottom: .7rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: $color-gray;
      cursor: pointer;
      user-select: none;

      &--active {
        color: $color-black;
        border-bottom: .3rem solid $color-brand;
      }
    }

    &__tabs-item:not(:last-child) {
      margin-right: 2rem;
    }
  }
</style>
